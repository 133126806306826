import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAGp4Yz3VJDLXwr8YTqbVPKQuAtgOsi-JM",
  authDomain: "langtrade-5bb65.firebaseapp.com",
  databaseURL: "https://langtrade-5bb65-default-rtdb.firebaseio.com",
  projectId: "langtrade-5bb65",
  storageBucket: "langtrade-5bb65.appspot.com",
  messagingSenderId: "1074720098070",
  appId: "1:1074720098070:web:0de7fecb91f298bf473237",
  measurementId: "G-DHT6Y4JSXY"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
